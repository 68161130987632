import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "distribution-channel"
    }}>{`Distribution Channel`}</h1>
    <p>{`Distribution Channels controls which organisations can sell, refund or inform on available products or salepoints.
The service implements a subset of the NetEx Sales Distribution Model -
(`}<a parentName="p" {...{
        "href": "http://www.netex-cen.eu/model/conceptual/part3/index.htm"
      }}>{`NetEx Part 3 : Model`}</a>{`). `}</p>
    <h2 {...{
      "id": "distribution-channel-registry"
    }}>{`Distribution Channel Registry`}</h2>
    <p>{`Distribution Channel Registry is a service for storing, handling and making available of distribution
channels to other Entur services.`}</p>
    <p>{`A distribution channel is linked to an organisation. Organisations may create and administer their distribution
channels by themselves - using the Entur Partner web application and Distribution API. Channels linked to Entur
may be used by all organisations.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <p><img alt="Distribution Model" src={require("./diagrams/dist-model.png")} /></p>
    <h2 {...{
      "id": "usage-context"
    }}>{`Usage Context`}</h2>
    <p>{`Distribution service is used primarily by Team PoP, Team Sales, and the Entur Partner web frontend.`}</p>
    <p><img alt="Distribution Usage Context" src={require("./diagrams/context.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      